<script setup>
import { ref, reactive, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import AppreciationForm from '@/views/appreciation/form/index.vue'
import { getAppreciationInfo } from '@/services/appreciation'

const router = useRouter()
const route = useRoute()

const firstLoading = ref(true)
const currentState = reactive({
  id: route.params.id || 0,
  title: route.params.id ? '编辑赏析资源' : '新建赏析资源'
})

const current = ref({})

const goBack = () => {
  router.go(-1)
}

onBeforeMount(async () => {
  if (currentState.id) {
    current.value = await getAppreciationInfo(currentState.id)
    firstLoading.value = false
  } else {
    firstLoading.value = false
  }
})
</script>
<template>
  <a-spin :spinning="firstLoading">
    <a-card :title="currentState.title">
      <template #extra><a @click="goBack">返回</a></template>
      <appreciation-form v-if="!firstLoading" :current="current" />
    </a-card>
  </a-spin>
</template>
<style lang="less" scoped>

</style>
